export default class PDFJs {
  init = (source, element) => {
    const iframe = document.createElement('iframe');

    iframe.src = `/pdfjs-dist/web/viewer.html?file=${source}`;
    iframe.width = '100%';
    iframe.height = '600px';
    iframe.style = 'border:none; margin-bottom:2rem';
    iframe.title = 'PDF Viewer';

    element.appendChild(iframe);
  };
}
