import React from 'react';
import './main-header.scss';

import { LanguageSwitcher, TranslatableText, TranslatedBlock } from './language';
import { Link } from 'gatsby';
import FontSizeChanger from './font-size-changer.js';
import EnspryngLogoEN from 'src/images/Enspryng_logo_EN.png';
import EnspryngLogoFR from 'src/images/Enspryng_logo_FR.png';
import PropTypes from 'prop-types';
import RocheLogo from 'src/images/Roche_logo.png';
import TabBar from './tab-bar';

export default class MainHeader extends React.Component {
  render() {
    return (
      <header className={'main-header'}>
        <div className={'menu-wrapper container'}>
          <h1 className={'branding'}>
            <Link to="/" className={'custom-logo-link'}>
              <TranslatedBlock language="english">
                <img src={EnspryngLogoEN} alt="ENSPRYNG logo" className={'logo-img'} />
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <img src={EnspryngLogoFR} alt="ENSPRYNG logo" className={'logo-img'} />
              </TranslatedBlock>
              <span className={'site-name'}>{this.props.siteTitle}</span>
            </Link>
          </h1>
          <div className={'top-right-container'}>
            <img src={RocheLogo} alt="Roche logo" className={'roche-logo-img'} />
            <div className={'font-size-changer-container'}>
              <span className={'text-size-label'}>
                <TranslatableText
                  dictionary={{
                    english: 'Text size:',
                    french: 'Taille des caractères :',
                  }}
                />
              </span>
              <FontSizeChanger
                className={'font-size-changer'}
                targets={['.tab-name', '.content-wrapper', '.site-footer']}
                options={{ stepSize: 1, range: 5 }}
              />
            </div>
            <LanguageSwitcher />
          </div>
        </div>
        <TabBar />
      </header>
    );
  }
}

MainHeader.propTypes = {
  siteTitle: PropTypes.string,
};

MainHeader.defaultProps = {
  siteTitle: '',
};
