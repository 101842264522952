import React from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Footer from './footer';
import Helmet from 'react-helmet';
import MainHeader from './main-header';
import PropTypes from 'prop-types';

export default function Layout({ title, showTab, rpgCode, className, children }) {
  return (
    <div className={className}>
      <AppStateContext.Consumer>
        {({ highContrast, language }) => (
          <>
            <Helmet
              title={'ENSPRYNG.ca - ENSPRYNG - ' + title[language]}
              meta={[{ name: 'robots', content: 'noindex,follow' }]}
            >
              <html lang={language === 'french' ? 'fr' : 'en'} />
              <script
                type="text/javascript"
                src={'https://cdn.cookielaw.org/consent/' + process.env.GATSBY_OT_SCRIPT_ID + '/OtAutoBlock.js'}
              ></script>
              <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                data-document-language="true"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script={process.env.GATSBY_OT_SCRIPT_ID}
              ></script>
              <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

              <body className={highContrast ? 'wcag2aa' : ''} />
            </Helmet>
            <MainHeader siteTitle={'ENSPRYNG.ca - ENSPRYNG - ' + title[language]} showTab={showTab} />
          </>
        )}
      </AppStateContext.Consumer>
      <main className={'container content-wrapper'}>{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  title: PropTypes.object.isRequired,
  showTab: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  showTab: false,
};
