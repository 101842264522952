import React from 'react';
import PropTypes from 'prop-types';

import './tab-bar.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { Link } from 'gatsby';
import { TranslatableText } from './language';

class Tab extends React.Component {
  render() {
    return (
      <div className="tab">
        <Link to={this.props.href} className={`tab-name ${this.props.active ? 'active' : ''}`}>
          <TranslatableText dictionary={this.props.textDict} />
        </Link>
      </div>
    );
  }
}

Tab.propTypes = {
  href: PropTypes.string.isRequired,
  active: PropTypes.bool,
  textDict: PropTypes.object.isRequired,
};

Tab.defaultProps = {
  active: false,
};

export default class TabBar extends React.Component {
  static contextType = AppStateContext;

  render() {
    const context = this.context;

    let tabs = <div className="tab" />;
    const section = context.section;
    const pageName = context.page;

    if (section === 'patient') {
      tabs = (
        <>
          <Tab
            href="/patient/"
            active={pageName === 'patient'}
            textDict={{
              english: 'ENSPRYNG Administration Video',
              french: 'Vidéo d’administration de ENSPRYNG',
            }}
          />
          <Tab
            href="/patient/injection-training-mat/"
            active={pageName === 'patient/injection-training-mat'}
            textDict={{
              english: 'ENSPRYNG Injection Training Mat',
              french: 'Sous-main pour l’injection de ENSPRYNG',
            }}
          />
          <Tab
            href="/patient/info-book/"
            active={pageName === 'patient/info-book'}
            textDict={{
              english: 'ENSPRYNG Patient and Supporter/Caregiver Info Book',
              french: 'Brochure d’information sur ENSPRYNG destinée au patient/soignant',
            }}
          />
        </>
      );
    }

    return (
      <div className={'tab-bar'}>
        <div className={'tab-content container'}>{tabs}</div>
      </div>
    );
  }
}

TabBar.propTypes = {};

TabBar.defaultProps = {};
