import React from 'react';

import './annotation.scss';

import { TranslatedBlock } from './language';

const Annotation = ({ fairBalance, hideEducationalPurpose }) => {
  if (!hideEducationalPurpose) {
    var educationalPurpose = (
      <>
        <TranslatedBlock language="english">
          This website is for educational purposes only. It is not intended to replace the advice and guidance of your
          healthcare team. If you have questions about ENSPRYNG treatment or your condition, talk to your doctor.
          <br />
          <br />
        </TranslatedBlock>
        <TranslatedBlock language="french">
          Ce site Web est offert à des fins éducatives seulement. II ne vise en aucun cas à remplacer l’opinion et les
          conseils de votre équipe de soins de santé. Si vous avez des questions sur le traitement par ENSPRYNG ou sur
          votre état de santé, veuillez consulter votre médecin.
          <br />
          <br />
        </TranslatedBlock>
      </>
    );
  }

  return (
    <div className="annotation">
      <TranslatedBlock language="english">
        {educationalPurpose}
        For complete information on ENSPRYNG, refer to the package insert contained in your ENSPRYNG carton, or call the
        COMPASS™ Program at <span className="nowrap">1-888-334-5956</span>.
      </TranslatedBlock>
      <TranslatedBlock language="french">
        {educationalPurpose}
        <p className="left">
          Pour obtenir des renseignements complets sur ENSPRYNG, consultez la notice d’emballage qui se trouve dans la
          boîte de ENSPRYNG ou appelez le programme COMPASS™ au <span className="nowrap">1-888-334-5956</span>.
        </p>
      </TranslatedBlock>
    </div>
  );
};

export default Annotation;
